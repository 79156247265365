<template>
  <LzModal
    v-if="canModalShow(name)"
    id="theme-modal"
    class="lzui-modal--large"
    :modal-title="t('components.theme_modal.title')"
    :modal-id="name"
    :can-cancel="true"
    :autoclose="false"
    @close-modal="cancelSelect"
    @bg-click="cancelSelect"
  >
    <section class="space-y-8">
      <div
        class="grid gap-8 p-6"
        :class="[gradeBand === '612' ? 'grid-cols-1 sm:grid-cols-3' : 'grid-cols-1 sm:grid-cols-2']"
      >
        <button
          v-for="(themeItem, index) in themes"
          :key="index"
          class="relative flex flex-col items-center rounded-3xl transition duration-300"
          :class="[
            themeActive === index
              ? 'bg-green-accent ring-green-accent ring-4'
              : 'hover:border-green-accent shadow-lg-100 hover:shadow-md-100 cursor-pointer',
            index === 'default' ? 'hidden' : '',
          ]"
          @click="selectTheme(index)"
        >
          <div
            :style="{ backgroundImage: `url(${themeItem.background})` }"
            :alt="themeItem.title"
            class="h-36 w-full rounded-t-3xl bg-cover bg-bottom"
          />
          <p class="text-primary px-6 py-3 font-semibold">{{ themeItem.title }}</p>
          <div
            v-if="themeActive === index"
            class="bg-green shadow-md-100 animate-fadeInScale absolute -bottom-2 -right-2 h-12 w-12 rounded-full p-2"
          >
            <InlineSvg path="icons/check" class="h-full w-full text-white" />
          </div>
        </button>
        <button
          class="btn btn--default relative m-1 flex flex-col space-y-2"
          :class="!themeActive ? 'border-green-accent border-2 bg-green-100 shadow-none' : ''"
          @click="selectTheme(null)"
        >
          <InlineSvg path="icons/no-symbol" class="h-16 w-16" />
          <div>{{ t('components.theme_modal.no_theme') }}</div>
          <div
            v-if="!themeActive"
            class="bg-green shadow-md-100 animate-fadeInScale absolute -bottom-2 -right-2 h-12 w-12 rounded-full"
          >
            <InlineSvg path="icons/check" class="h-full w-full text-white" />
          </div>
        </button>
      </div>
      <footer class="flex flex-row justify-center space-x-4 py-2">
        <button class="btn" @click.stop="cancelSelect">{{ t('components.theme_modal.cancel') }}</button>
        <button class="btn btn--primary" @click.stop="saveTheme">{{ t('components.theme_modal.save') }}</button>
      </footer>
    </section>
  </LzModal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
import { LzAnalytics } from 'clients'

const name = 'ThemeModal'

const messages = merge({}, namespaceLocaleObj(components, 'components', { only: ['theme_modal'] }))
const { t } = useI18n({ messages })
const { themes, theme, setTheme, gradeBand } = useCurrentUserStore()
const close = () => closeModal(name)
const themeActive = ref(null)
onMounted(() => {
  themeActive.value = theme.value
})

const saveTheme = () => {
  setTheme(themeActive)
  LzAnalytics.track('Theme Chosen', { source: 'modal', action: 'theme chosen', value: themeActive.value })
  close()
}
const selectTheme = (index) => {
  themeActive.value = index
}
const cancelSelect = () => {
  themeActive.value = theme.value
  LzAnalytics.track('Theme Modal', { source: 'modal', action: 'close' })
  close()
}
</script>
